import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
// import PropTypes from 'prop-types';
import Logo from '../../layout/header/logo';
import LoginForm from './loginForm';
import EntryExitScheduler from '../../animations/entryExitScheduler';
import Modal from '../ui/modal';
import Portal from '../../../helpers/portal';

const LoginTemplate = () => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState('unsent');

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          oracle {
            url
            website
          }
        }
      }
    }
  `);

  const { url, website } = site.siteMetadata.oracle;

  const login = async (email) => {
    try {
      setStatus('loading');
      await fetch(`${url}/stripe/portal_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, website }),
      });
      setStatus('success');
    } catch (e) {
      setStatus('error');
      setError(e.message);
    }
  };

  const onSubmit = (email) => {
    login(email);
  };

  const getModalBody = () => {
    switch (status) {
      case 'success':
        return (
          <>
            <h3 className="SB28 text-center text-accent-main mb-4 px-8">
              Magic link sent{' '}
              <span role="img" aria-label="Magic wand emoji">
                🪄
              </span>
            </h3>
            <p className="R16-new contrast-high text-center mb-0">
              If your mail address is linked to a valid account, you&apos;ll
              shortly receive an email from us. <br /> Check your inbox!
            </p>
          </>
        );
      case 'error':
      default:
        return (
          <>
            <h3 className="SB28 text-center text-red-plain mb-4 px-8">
              A wild error appears
            </h3>
            <p className="R16-new contrast-high text-center mb-6">
              {error.toString() || 'An unknown error occured'}
            </p>
            <p className="R14-LH contrast-low text-center">
              If the problem persists, send us the message above at{' '}
              <a
                className="contrast-low underline"
                href="mailto:support-web@spliceapp.com"
              >
                support-web@spliceapp.com
              </a>
            </p>
          </>
        );
    }
  };

  return (
    <>
      <div className="py-4 border-b border-accent-secondary-blue-40">
        <div className="container h-11 flex items-center">
          <h1 className="leading-none w-20">
            <Link to="/">
              <Logo />
            </Link>
          </h1>
        </div>
      </div>

      <section className="container space-around">
        <div className="max-w-half-column">
          <h2 className="text-title mb-4 md:mb-6">
            Login
            <br />
            <span className="block text-gradient bg-splice-gradient-horizontal">
              Without Password.
            </span>
          </h2>
          <p className="mb-10">
            Type your email below. You will receive a unique, temporary link to
            manage your subscription.
          </p>
          <LoginForm isLoading={status == 'loading'} onSubmit={onSubmit} />
        </div>
      </section>

      <Portal>
        <div
          className={`fixed inset-0 flex justify-center items-center bg-black-70 transition-opacity duration-500 ${
            status == 'success' || status == 'error'
              ? 'opacity-100 ease-out'
              : 'opacity-0 ease-in pointer-events-none'
          }`}
        >
          <EntryExitScheduler>
            {status == 'success' || status == 'error' ? (
              <Modal key="message" onDismiss={() => setStatus('unsent')}>
                {getModalBody()}
              </Modal>
            ) : null}
          </EntryExitScheduler>
        </div>
      </Portal>
    </>
  );
};

// LoginTemplate.propTypes = {};

export default LoginTemplate;
